import css from '@styled-system/css'
import styled from 'styled-components'
import { fontWeight } from 'styled-system'

const ListItem = styled.li<{ dotColor?: string; fontWeight?: string }>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  
  ::marker {
    ${(props) =>
      css({
        color: props.dotColor || 'grey.3',
      })}
    }

  }

  ${css({
    padding: 0,
  })}

  ${fontWeight}
`

export default ListItem
