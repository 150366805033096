import { GlobalError } from 'react-hook-form'
import { useEffect, useRef } from 'react'

import Link from '@ui/common/Link'
import { ErrorMessage } from '@ui/form/ErrorMessage'
import Stack from '@ui/layout/stack'
import { Body } from '@ui/typography/Body'
import { contactUsPath, loginPath } from 'routes/static'
import { space } from '@ui/theme/spacing'
import ListItem from '@ui/common/ListItem'

type SubmissionErrorProps = {
  error: GlobalError
}

const SubmissionError = ({ error }: SubmissionErrorProps) => {
  const scrollRef = useRef<HTMLHeadingElement>(null)
  const errorType = error?.type

  // These error codes will automatically result in the user being redirected to a different page
  // In this case we don't want to display an error message
  const isRedirectError =
    errorType === 'LOGGED_OUT_UNAUTHORIZED' || errorType === 'FULL_SESSION_REQUIRED'

  useEffect(() => {
    if (scrollRef.current) {
      const rect = scrollRef.current.getBoundingClientRect()
      const top = rect.top + window.scrollY - space[4]
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }, [])

  const getErrorMessage = () => {
    switch (errorType) {
      case 'ONBOARDING_USER_MISSING':
        return (
          <>
            Unable to save changes. Please{' '}
            <Link href={loginPath} rel='noreferrer'>
              login
            </Link>{' '}
            to continue with sign up.
          </>
        )
      case 'ONBOARDING_PROFILE_MISSING':
        return (
          <>
            Unable to save changes. Please ensure you have completed previous steps before
            proceeding.
          </>
        )
      case 'ONBOARDING_EXISTING_USER':
      case 'ONBOARDING_PROFILE_MISMATCH':
        return (
          <Stack gap='4'>
            <ErrorMessage>
              {`An account with this email address already exists. Please use it to `}
              <Link href={loginPath} rel='noreferrer'>
                login
              </Link>
              {` or enter a new email to continue.`}
            </ErrorMessage>
            <ErrorMessage>
              {'For support, please contact our '}
              <br />
              <Link variant='primary' href={contactUsPath} rel='noreferrer'>
                Help Center
              </Link>
            </ErrorMessage>
          </Stack>
        )
      case 'INTERNAL_SERVER_ERROR':
        return (
          <Stack gap='4'>
            <ErrorMessage>
              We apologize for the inconvenience. An unknown error has occurred.
            </ErrorMessage>
            <ErrorMessage>
              {'For support, please contact our '}
              <br />
              <Link variant='primary' href={contactUsPath} rel='noreferrer'>
                Help Center
              </Link>
            </ErrorMessage>
          </Stack>
        )
      case 'STRIPE_CARD_DECLINE_ERROR':
        return (
          <Stack gap='3'>
            <ErrorMessage>
              It looks like your bank didn&apos;t approve the payment which can happen for a number
              of reasons.
            </ErrorMessage>
            <Body color='errorRed' fontWeight='medium'>
              Please try the following:
            </Body>
            <Stack gap='2' as='ul'>
              <ListItem>Double-check your payment details.</ListItem>
              <ListItem>Contact your bank for more information.</ListItem>
              <ListItem>Try using a different payment method.</ListItem>
            </Stack>
          </Stack>
        )
      case 'CANNOT_REQUEST_SELF_CHECK_GENERIC':
      case 'EXISTING_BACKGROUND_CHECK_FOUND':
        return (
          <Stack gap='4'>
            <ErrorMessage>
              We apologize for the inconvenience. An unknown error has occurred.
            </ErrorMessage>
            <ErrorMessage>
              {'For support, please contact our '}
              <br />
              <Link variant='primary' href={contactUsPath} rel='noreferrer'>
                Help Center
              </Link>
            </ErrorMessage>
          </Stack>
        )
      default:
        return (
          error?.message ?? (
            <Stack gap='4'>
              <ErrorMessage>
                We apologize for the inconvenience. An unknown error has occurred.
              </ErrorMessage>
              <ErrorMessage>
                {'For support, please contact our '}
                <br />
                <Link variant='primary' href={contactUsPath} rel='noreferrer'>
                  Help Center
                </Link>
              </ErrorMessage>
            </Stack>
          )
        )
    }
  }

  if (isRedirectError) {
    return null
  }

  return (
    <div ref={scrollRef}>
      <Body>
        <ErrorMessage>{getErrorMessage()}</ErrorMessage>
      </Body>
    </div>
  )
}

export default SubmissionError
